import { createRoot } from 'react-dom/client'
import ReactPixel from 'react-facebook-pixel'
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css'
import wrapRootElement from './src/wrap-root-element'

const pixelId = process.env.FACEBOOK_PIXEL

const options = {
  autoConfig: true,
  debug: false,
}

export const onClientEntry = () => {
  if (typeof window !== 'undefined') {
    try {
      ReactPixel.init(pixelId, {}, options)
      ReactPixel.pageView()
    } catch (error) {}
  }
}

export { wrapRootElement }

export const replaceHydrateFunction = () => {
  return (element, container) => {
    createRoot(container).render(element)
  }
}
