/* eslint-disable prettier/prettier */
import jwtDecode from 'jwt-decode'

export const isBrowser = () => typeof window !== 'undefined'

const getVerifyTokenFetch = async (hash, callBack) => {
  if (hash) {
    await fetch(
      `${process.env.OKY_SELF_SERVICE_URL}/api/verify-token/${hash}`,
      {
        method: 'get',
      },
    )
      .then(response => response.json())
      .then(
        respData => {
          if (respData.statusCode === 200) {
            setUser({
              token: respData.data.token,
              user: respData.data.user,
            })
            callBack(true, respData.data.token, respData.data.user)
            return true
          }
          return false
        },
        () => {
          return false
        },
      )
  }
  return false
}

function removeAccentsAndSpecialChars(str) {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z\s]/g, '')
}

export const cleverTapUserIdentify = user => {
  const firstName = removeAccentsAndSpecialChars(user?.firstName || '')
  const lastName = removeAccentsAndSpecialChars(user?.lastName || '')
  if (user) {
    const clevertap = require('clevertap-web-sdk')
    clevertap.onUserLogin.push({
      Site: {
        Name: `${firstName} ${lastName}`,
        'First Name': `${firstName}`,
        'Last Name': `${lastName}`,
        Identity: user?.id,
        Phone: `+${user?.indicativo}${user?.phoneNumber}`,
        DOB: new Date(),
        'MSG-sms': user?.msgSms,
        'MSG-email': user?.msgEmail,
        'MSG-push': user.msgPush,
        'MSG-whatsapp': user?.msgWhatsapp,
        Country: user?.country?.name,
        Date_I: `$D_${user?.dateI}`,
      },
    })
  }
}
export const cleverTapUpdateUserIdentify = data => {
  if (data) {
    const clevertap = require('clevertap-web-sdk')
    clevertap.onUserLogin.push({
      Site: {
        'Default Country': data?.receptorCountry,
        Default_Country: data?.CountryId,
        Default_Country_ID: data?.receptorCountryId,
        'Digital Payment': data?.digitalPayment,
        Balance: data?.mainWallet,
        Promotional_Balance: data?.mainWalletPromotional,
        Promotional_Balance_Due: `$D_${data?.expirationDatePromotionalWallet}`,
        Sending_Fee_Balance: data?.feesWallet,
        Sending_Fee_Balance_Due: `$D_${data?.expirationDateFeesWallet}`,
      },
    })
  }
}

export const cleverTapUpdateIdentifyProps = (props, opt) => {
  if (window.com_my_oky) {
    if (opt?.update) {
      window.com_my_oky.pushProfile(JSON.stringify(props))
    } else {
      window.com_my_oky.onUserLogin(JSON.stringify(props))
    }
  } else if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.com_my_oky
  ) {
    const message = {
      action: opt?.update ? 'pushProfile' : 'onUserLogin',
      properties: props,
    }
    window.webkit.messageHandlers.com_my_oky.postMessage(message)
  } else {
    const clevertap = require('clevertap-web-sdk')
    if (clevertap) {
      if (opt?.update) {
        clevertap.profile.push({
          Site: {
            ...props,
          },
        })
      } else {
        clevertap.onUserLogin.push({
          Site: {
            ...props,
          },
        })
      }
    }
  }
}

export const cleverTapIdentify = user => {
  const firstName = removeAccentsAndSpecialChars(user?.firstName || '')
  const lastName = removeAccentsAndSpecialChars(user?.lastName || '')
  if (user) {
    let indicativo = ''
    if (user?.phoneIndicative) {
      indicativo = user?.phoneIndicative
    } else if (user?.country?.callingCode) {
      indicativo = user?.country?.callingCode
    } else {
      indicativo = user?.indicativo
    }

    let name = firstName
    if (user?.lastName) {
      name = `${firstName} ${lastName}`
    }

    if (window.com_my_oky) {
      const props = { Identity: user.id, Email: user?.email }
      window.com_my_oky.onUserLogin(JSON.stringify(props))
    } else if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.com_my_oky
    ) {
      const props = { Name: name, Email: user?.email }
      const message = { action: 'onUserLogin', properties: props }
      window.webkit.messageHandlers.com_my_oky.postMessage(message)
    } else {
      const clevertap = require('clevertap-web-sdk')
      clevertap.onUserLogin.push({
        Site: {
          Name: `${firstName} ${lastName}`,
          'First Name': `${firstName}`,
          'Last Name': `${lastName}`,
          Identity: user.id,
          Phone: `+${indicativo}${user.phoneNumber}`,
          Country: user?.country?.name,
          Date_I: `$D_${user?.dateI}`,
        },
      })
    }
  }
}

export const getVerifyToken = async (hash, callBack) => {
  getVerifyTokenFetch(hash, callBack)
}

export const getUser = () => {
  const gatsbyUser =
    isBrowser() && window.localStorage.getItem('gatsbyUser') !== 'undefined'
      ? window.localStorage.getItem('gatsbyUser')
      : null
  if (isBrowser() && gatsbyUser) {
    if (JSON.parse(gatsbyUser).id) {
      return JSON.parse(gatsbyUser)
    } else {
      return {
        ...JSON.parse(gatsbyUser),
        id: JSON.parse(gatsbyUser).user.id || '',
      }
    }
  } else
    return {
      id: '',
    }
}

export const setUser = ({ token, user }) => {
  if (token) {
    window.localStorage.setItem('gatsbyUser', JSON.stringify({ token, user }))
  } else {
    window.localStorage.removeItem('gatsbyUser')
  }
}

export const isLoggedIn = () => {
  const user = getUser()
  return !!user.token
}

export const logout = callback => {
  setUser({ token: null })
  callback()
}


export const checkSessionTokenValidity = token => {
  try {
    const decodedToken = jwtDecode(token)

    const currentTime = Date.now() / 1000
    if (decodedToken.exp < currentTime) {
      return false
    } else {
      return true
    }
  } catch (error) {
    console.error(error)
    return false
  }
}


const NAV_STACK_KEY = 'navigationStack'
const getNavigationStack = () => {
  if (isBrowser()) {
    const stack = JSON.parse(window.localStorage.getItem(NAV_STACK_KEY))
    return Array.isArray(stack) ? stack : []
  }
  return []
}
const setNavigationStack = (stack) => {
  if (isBrowser()) {
    window.localStorage.setItem(NAV_STACK_KEY, JSON.stringify(stack))
  }
}
export const setRedirectUrl = (route) => {
  if (!route || typeof route !== 'string') return
  const stack = route.includes('app/home') ? [] : getNavigationStack()
  if(('' + route).trim() === 'app/home'){
    setNavigationStack([route])
  }else{
    if (stack.length === 0 || stack[stack.length - 1] !== route) {
      stack.push(route)
      setNavigationStack(stack)
    }
  }
}

  export const goBackBasicService = () => {
    const stack = getNavigationStack()
    if (stack.length > 1) {
      stack.pop()
      setNavigationStack(stack)
      return stack[stack.length - 1]
    }
  
    return '/app/home'
  }
  export const goBackService = () => {
    const stack = getNavigationStack()
    if (stack.length > 3) {
      const nextRoute = stack[stack.length - 3]
  
      if (nextRoute.includes('/app/checkout')) {
        stack.pop()
        stack.pop()
      } else {
        stack.pop()
      }
  
      setNavigationStack(stack)
      return stack[stack.length - 1] || '/app/home'
    }else if (stack.length > 1) {
      stack.pop()
      setNavigationStack(stack)
      return stack[stack.length - 1]
    }
  
    return '/app/home'
  }
  